<template>
  <div>
    <button class="btn btn-primary" id="treeBtnStart" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Info</button>

    <div class="show offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
      id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">


        <h4 class="offcanvas-title" id="offcanvasScrollingLabel">Mérés és monitoring állomások a Szigetközben</h4>
        <button type="button" class="btn btn-primary" id="btnX" data-bs-dismiss="offcanvas" aria-label="Close">
          <i class="fa-solid fa-arrow-left"></i>
        </button>

      </div>

      <div class="logos">
        <div class="picture1"><!--<img src="images/logo.jpg" class="rounded mx-auto d-block" alt="...">--></div>
        <div class="searchAndLogo">
          <!--<img src="images/logo.jpg" class="rounded mx-auto d-block" alt="...">-->
          <div class="searchbar">
            <input class="searchbarInput" v-model="searchQuery" placeholder="Keresés..." />
          </div>
          <div class="eudvizLogo">
            <img src="../../public/images/logo2.png" class="picture2" alt="">
          </div>
          <!-- <div class="picture2"></div> -->
        </div>
      </div>

      <div class="offcanvas-body">
        <div class="overflow-y-scroll">
          <table class="table-transparent" id="liveData-table">
            <tr>
              <th id="tableHead">Állomás neve</th>
              <th id="tableHead">Vízfolyás Kút száma</th>
              <th><button class="btn btn-primary" id="helloContainer" @click="clearMap()"><i class="bi bi-pin-map-fill"
                    id="helloButton"></i></button><i @click="changeClass()" class="fa-solid fa-eye" id="showPopUp"></i></th>
            </tr>
            <tr v-for="a in filteredList" :key="a.__EMPTY">
              <th class="textColumn">{{ a.__EMPTY_2 }}</th>
              <!-- <th :style="{ 'background-color': a.color, 'opacity': none }"> </th> -->
              <th class="textColumn">{{ a.__EMPTY_1 }}</th>
              <th><Button class="btn btn-primary" :id="'infoButton' + a.__EMPTY"
                  @click="showPoi(lat = a.__EMPTY_14, lon = a.__EMPTY_13, title = a.__EMPTY_2, description = a.__EMPTY_4, description2 = a.__EMPTY_3, icons = a.__EMPTY_5, icons2 = a.__EMPTY_6, icons3 = a.__EMPTY_7, icons4 = a.__EMPTY_8, icons5 = a.__EMPTY_9, icons6 = a.__EMPTY_10, index = a.__EMPTY); infoButtonDisable(a.__EMPTY); flyToPoi(lat = a.__EMPTY_14, lon = a.__EMPTY_13)">info</Button>
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="container">
      <Mapbox :accessToken="accessToken" :mapStyle="mapStyle" :zoom="zoom" :center="center" id="map"
        @load="onMapLoaded" />
    </div>

  </div>


  <!--   <div class="modelView" id="modelContainer1" ref="canvasContainer">
    <button class="btn btn-secondary" id="back-button" @click="goBack()">
      <i class="fa-solid fa-arrow-left"></i>
    </button>
  </div> -->
</template>
<script>

import Mapbox from "vue-mapbox";
import MapboxGl from "mapbox-gl";
import szigetkoz from "../components/szigetkoz.json";

export default {
  name: "MapComponent",
  components: {
    Mapbox,
  },
  data() {
    return {
      map: null,
      originalMap: null,
      szigetkoz: szigetkoz,
      searchQuery: "",
      markerRefs: [],
      showPopUp: undefined,

    };
  },
  async created() {
    this.map = null;
  },
  computed: {
    filteredList: function () {
      return this.szigetkoz.filter((item) => {
        if (item.__EMPTY_2) {
          let descLow = item.__EMPTY_2;
          return descLow.toLowerCase().includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
  async mounted() {
    this.map = new MapboxGl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      projection: "globe",
      accessToken:
        "pk.eyJ1IjoiY3NhYmluZW1ldGgiLCJhIjoiY2xlOGt3YjVmMGdkbjN2bmJ6cHk4ZWN6eCJ9.VwQ_9iKWgBNkt6lEs0vsvg",
      zoom: 11,
      center: [17.48, 47.85],
      antialias: true,
      pitch: 45,
      bearing: -18,
    });
    this.map.on("style.load", () => {
      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#3AB394",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    });
    var nav = new MapboxGl.NavigationControl();
    this.map.addControl(nav, 'bottom-right');

  },
  methods: {
    clearMap() {
      this.map.flyTo({ center: [17.48, 47.85], zoom: 11 });
      for (const item of this.markerRefs) {
        document.getElementById('infoButton'+item.index).removeAttribute("disabled");
      }
      for (let m of this.markerRefs) {
        m.marker.remove()
      }
      this.markerRefs = [];
      if (document.getElementsByClassName("mapboxgl-popup-close-button")[0]) {
        document.getElementsByClassName("mapboxgl-popup-close-button")[0].click();
      }
      document.getElementById('showPopUp').classList.remove('fa-solid');
      document.getElementById('showPopUp').classList.remove('fa-eye-slash');
      document.getElementById('showPopUp').classList.add('fa-solid');
      document.getElementById('showPopUp').classList.add('fa-eye');
    },


    changeClass() {
      if (document.getElementById('showPopUp').classList.contains('fa-eye')) {
        document.getElementById('showPopUp').classList.remove('fa-eye');
        document.getElementById('showPopUp').classList.add('fa-eye-slash');
      } else if (document.getElementById('showPopUp').classList.contains('fa-eye-slash')) {
        document.getElementById('showPopUp').classList.remove('fa-eye-slash');
        document.getElementById('showPopUp').classList.add('fa-eye');
}
        if (this.markerRefs[this.markerRefs.length - 1]) {
          this.markerRefs[this.markerRefs.length - 1].marker.togglePopup()
        }
             
    },

    infoButtonDisable(index) {
      document.getElementById('infoButton' + index).setAttribute("disabled", true);
      if (this.markerRefs.length > 1) {
/*         console.log("infoButton" + this.markerRefs[0].index) */
        document.getElementById("infoButton" + this.markerRefs[0].index).removeAttribute("disabled");
      }
    },

    showPoi(lan, lot, title, description, description2, icons, icons2, icons3, icons4, icons5, icons6, index) {
      if (!document.getElementById("Poi" + index)) {
        const el = document.createElement('div');
        el.className = 'marker';
        el.id = 'Poi' + index;
        el.style.backgroundImage = 'url(images/marker-icon.png)';
        el.style.width = '22px';
        el.style.height = '35px';
        el.style.backgroundSize = 'cover';
        el.properties = {
          title: title,
          description: description,
          description2: description2,
          icons: icons,
          icons2: icons2,
          icons3: icons3,
          icons4: icons4,
          icons5: icons5,
          icons6: icons6,
        };

        if (icons) el.properties.icons = '/images/vizallas.png';
        if (icons2) el.properties.icons2 = '/images/vizhomerseklet.png';
        if (icons3) el.properties.icons3 = '/images/vizhozam.png';
        if (icons4) el.properties.icons4 = '/images/hordalek.png';
        if (icons5) el.properties.icons5 = '/images/csapadek.png';
        if (icons6) el.properties.icons6 = '/images/parolgas.png';

        let marker = new MapboxGl.Marker(el)
          .setLngLat([lan, lot])

          .addTo(this.map)



        marker.setPopup(new MapboxGl.Popup({ offset: 25, closeOnClick: false }) // add popups
          .setHTML(
            `<h4>${el.properties.title}</h4>
          <h5>${el.properties.description}</h5>
          <h5>${el.properties.description2}</h5>
          ${el.properties.icons ? `<h6><img src="${el.properties.icons}"  title="Vízállás" alt="icon"></h6><h5>Vízállás</h5>` : ''}
          ${el.properties.icons2 ? `<h6><img src="${el.properties.icons2}"  title="Vízhőmérséklet" alt="icon"></h6><h5>Vízhőmérséklet</h5>` : ''}
          ${el.properties.icons3 ? `<h6><img src="${el.properties.icons3}"  title="Vízhozam" alt="icon"></h6><h5>Vízhozam</h5>` : ''}
          ${el.properties.icons4 ? `<h6><img src="${el.properties.icons4}"  title="Hordalék" alt="icon"></h6><h5>Hordalék</h5>` : ''}
          ${el.properties.icons5 ? `<h6><img src="${el.properties.icons5}"  title="Csapadék" alt="icon"></h6><h5>Csapadék</h5>` : ''}
          ${el.properties.icons6 ? `<h6><img src="${el.properties.icons6}"  title="Párolgás" alt="icon"></h6><h5>Párolgás</h5>` : ''}`
          ))

          .addTo(this.map)
        this.markerRefs.push({ index, marker })
        if (document.getElementById('showPopUp').classList.contains('fa-eye')) { marker.togglePopup() }
        if (this.markerRefs.length > 2) {
          document.getElementById("Poi" + this.markerRefs[0].index).remove();
          if (document.getElementsByClassName("mapboxgl-popup-close-button")[0]) {
            document.getElementsByClassName("mapboxgl-popup-close-button")[0].click();
          }
          this.markerRefs.shift();
        }
        if (this.markerRefs.length > 1) {

          this.markerRefs[0].marker.getPopup().remove()
        }

        console.log(this.markerRefs);

        //console.log( document.getElementById(this.poiArray[0]));
      }
        else {
          console.log(!document.getElementById("infoButton" + this.markerRefs[0].index).disabled)
          if(document.getElementById('showPopUp').classList.contains('fa-eye')){
            this.markerRefs[0].marker.togglePopup();
            this.markerRefs[1].marker.togglePopup();
            }
            this.markerRefs.reverse();
            if(!document.getElementById("infoButton" + this.markerRefs[0].index).disabled){
              document.getElementById("infoButton" + this.markerRefs[0].index).setAttribute("disabled", true);
              document.getElementById("infoButton" + this.markerRefs[1].index).removeAttribute("disabled");   
            }else{
              document.getElementById("infoButton" + this.markerRefs[1].index).setAttribute("disabled", true);
              document.getElementById("infoButton" + this.markerRefs[0].index).removeAttribute("disabled");
            }
          
      }
    },
    flyToPoi(lat, lon) {
      this.map.flyTo({ center: [lat, lon], zoom: 11 });
    },
  },
  unmounted() {
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

.searchAndLogo {
  padding-top: 5%;
  display: flex;
}

.eudvizLogo {
  /* margin-left: 30%; */
  width: 40%;
  display: flex;
  float: right;
  padding-left: 10%;
  /* padding-right: 5%; */
  /* justify-content: right; */
}

.searchbar {
  /* display: inline; */
  margin-left: 2%;
  margin-top: 10%;
  width: 60%;
}

.searchbarInput {
  width: 100%;
}

.textColumn {
  text-align: left;
  padding-left: 1%;
  word-wrap: break-word;
  /* color: black; */
}

#tableHead {
  /* font-size: 18px; */
  font-weight: bold;
  /* color: #312783; */
}

#helloContainer {
  margin: 5% auto;
}

.btn {
  color: white;
  z-index: 1000;
  border: 1px solid #312783;
  margin: 10px;
  width: auto;
  background-color: #2C3783;
  position: relative;
  visibility: always show;
  font-family: myFirstFont;
  display: block;
}

.btn:hover {
  background-color: #24658B;
  transition: 0.7s;
}

.btn-row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

th {
  border: 1px solid #198584;
  /* padding: 5px; */
  color: white;
}

td {
  border: 1px solid #198584;
  /* padding: 5px; */
  font-family: myFirstFont;
}

#modelContainer1 {
  border: none;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-item {
  object-fit: cover;
}

#back-button {
  position: absolute;
  width: auto;
  left: 10px;
  top: 10px;
  z-index: 100;
}

@font-face {
  font-family: myFirstFont;
  src: url(../../public/Montserrat-Regular.ttf);
}

#map {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

canvas {
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#info-table {
  font-family: myFirstFont;
}

#offcanvas-body {
  font-family: myFirstFont;

}

#offcanvasScrollingLabel {
  font-weight: bold;
  font-size: large;
}

#liveData-table {
  display: block;
  color: #fff;
  font-family: myFirstFont;
  min-height: 18vh;
  max-height: 60%;
  overflow: auto;
  width: 95%;
}

#liveData-table.table-transparent {
  display: block;
  margin-top: 10px;
  color: #fff;
  font-family: myFirstFont;
  overflow: auto;
}

.overflow-y-scroll {
  background-color: transparent;
  color: transparent;
  overflow-x: hidden;
  overflow-y: hidden !important;
}


#closeX {
  position: absolute;
  top: -50px;
  right: -10px;
  width: auto;
}

#closeX2 {
  position: absolute;
  top: -50px;
  right: -10px;
  width: auto;
  height: auto;
  border-color: #312783;
  border-radius: 15%;
}

.btn.btn-secondary.dropdown-toggle {
  margin-top: 1vh;
  display: block;
}

#btnX {
  display: flex;
  text-align: center;
  font-family: myFirstFont;
  width: auto;
  color: white;
  background-color: #2C3783;
  border-color: #312783;
  border-radius: 25%;
}

#btnX:hover {
  background-color: #24658B;
  transition: 0.7s;
}

#treeBtnStart {
  position: relative;
  display: flex;
  z-index: 110;
  margin-top: 5%;
  left: 0;
  right: 10%;
  width: auto;
  background-color: #299879;
  justify-content: center;
}

#treeBtnStart:hover {
  background-color: #198584;
  transition: 0.7s;
}

#treeBtn {
  position: relative;
  display: flex;
  z-index: 110;
  margin-top: 5%;
  left: 0;
  right: 10%;
  width: auto;
  background-color: #2C3783;
  justify-content: center;
}

.picture1 {
  display: flex;
  margin-left: 2%;
  height: 110px;
  width: 96%;
  background-image: url(../../public/images/logo.png);
  background-color: rgb(255, 253, 253);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  top: 0;
  position: relative;
}

.picture2 {
  height: 100px;
  max-width: 100px;
  background-color: rgb(255, 253, 253);
  size: cover;
  border: 2px solid #312783;
  border-radius: 50px;
}

.offcanvas-start {
  background-color: #2FAC66;
  font-family: myFirstFont;
}

.offcanvas-title {
  color: white;
  font-family: myFirstFont;
  font-size: large;
  display: flex;
  text-align: center;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #24658B;
}

::-webkit-scrollbar-thumb {
  background-color: #312783;
  height: 20%;
}

#treeBtn:hover {
  background-color: #4A8376;
  transition: 0.7s;
}

.video-container {
  position: relative;
  display: block;
  justify-content: center;
  margin-top: 20px;
}

.videoLinks {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#videoLink {
  display: inline;
  position: relative;
  display: flex;
  width: auto;
  text-decoration: none;
  justify-content: center;
  color: #fff;
}

#player {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

#offcanvasScrolling {
  width: 25%;
  /* margin-top: 20%; */
  background-image: url(../../public/images/white.png);
  background-blend-mode: soft-light;
  /* opacity: 0.5; */
  /* filter: brightness(50%); */
  /* background-position-y: 100%; */
  background-position: bottom right;
  background-size: 38%;
  background-repeat: no-repeat;
  /* background-size: contain; */
  /* background-position: center; */
  /* background-position: right; */
}

@media (max-width: 1685px) {
  #offcanvasScrolling {
    width: 28%;
  }
}

@media (max-width: 1430px) {
  #offcanvasScrolling {
    width: 40%;
  }
}

@media (max-width: 996px) {
  #offcanvasScrolling {
    width: 60%;
  }
}

@media (max-width: 662px) {
  #offcanvasScrolling {
    width: 70%;
  }
}

@media (max-width: 600px) {
  #offcanvasScrolling {
    width: 100%;
  }
}
</style>

<style>
.mapboxgl-popup {
  max-width: 200px;
  color: #fff;
  background-color: transparent;
}

.mapboxgl-popup-content {
  font-family: myFirstFont;
  color: #fff;
  background-color: #299879;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #299879;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #299879;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #299879;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #299879;
}

.marker {
  background-size: cover;
  width: 30px;
  height: 45px;
  pointer-events: none !important;
}

.mapboxgl-popup-close-button {
  visibility: hidden;
  background-color: #2C3783;
  color: #fff;
  border-radius: 15%;
  margin-top: -20%;
  border-color: #312783;
  border-style: solid;
  border-width: thin;
}

.mapboxgl-popup-close-button:hover {
  visibility: hidden;
  background-color: #24658B;
}

#showPopUp {
  cursor: pointer;
  color: #2C3783;
}

.hide {
  visibility: hidden;
}
</style>