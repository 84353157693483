import { createRouter, createWebHistory } from "vue-router";
import InsulaMagna from "../components/InsulaMagna.vue";
/* import LoginPage from "../components/LoginPage.vue"; */
/* import dataProvider from "../utils/dataProvider.js"; */

/* const dp = dataProvider(); */

const routes = [
    {
        path: "/",
        name: InsulaMagna,
        component: () =>
        import("../components/InsulaMagna.vue"),
        meta: { requiresAuth: true },
    },
   /*  {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    } */
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

  

export default router;